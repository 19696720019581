// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maps-index-js": () => import("./../src/pages/Maps/index.js" /* webpackChunkName: "component---src-pages-maps-index-js" */),
  "component---src-pages-page-not-found-index-js": () => import("./../src/pages/PageNotFound/index.js" /* webpackChunkName: "component---src-pages-page-not-found-index-js" */),
  "component---src-pages-404-index-js": () => import("./../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-yo-index-js": () => import("./../src/pages/yo/index.js" /* webpackChunkName: "component---src-pages-yo-index-js" */)
}

